import {Component, OnInit, ViewChild, Injector} from "@angular/core";
import {UiConfigService} from "../ui/ui-config.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {ModalDialogComponent} from "../utilities/components/modal-dialog/modal-dialog.component";
import {TransactionInfoService} from "../services/transaction-info.service";
import {ClaimService} from "../services/claim.service";
import {StoredDataService} from "../services/stored-data-service";
import {TransactionInfo} from "../model/transaction-info.model";
import {Claim} from "../model/claim.model";
import {TranslateService} from "@ngx-translate/core";
import {AutoSaveService} from "../services/autosave.service";
import {Product} from "../model/product.model";
import {PageService} from "../services/page.service";
import { Version } from "../../version";
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import { SideMenuService } from "../services/side-menu.service";
import {IntegrationService} from "../services/integrationservice";
import {ClaimsBaseComponent} from "../claim-pages/claim-base.component";
import {takeUntil} from "rxjs";
import { DynamicRoutingService } from "../services/dynamic-routing.service";
import { UtilitiesService } from "../utilities/utilities.service";
import { AutoSaveForm } from "../model/autosave-form.model";
import {PolicyAuthJson} from '../model/policy.auth-model';
import {PolicyVerifyForm} from "../model/policy-verify-form.model";
import {DateService} from "../ui/ui-datepicker/date.service";

@Component({
    selector: 'claim-boot',
    templateUrl: './claim-boot.component.html',
    styleUrls: ['./claim-boot.component.scss']
})
export class ClaimBootComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild(ModalDialogComponent, {static: true})
    modal: ModalDialogComponent;
    errorMsg: string;
    transactionInfo: TransactionInfo;

    //Services
    private uiConfigService: UiConfigService;
    private transactionInfoService: TransactionInfoService;
    private claimService: ClaimService;
    public  translate: TranslateService;
    private storedDataService: StoredDataService;
    private autoSaveService: AutoSaveService;
    private pageService : PageService;
    private sideMenuService: SideMenuService;
    public  integrationService: IntegrationService;
    private googleAnalyticsService: GoogleAnalyticsService;
    private dynamicRoutingService: DynamicRoutingService;
    private dateService: DateService;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private injector : Injector ) {
        super(injector);
        this.uiConfigService = this.injector.get(UiConfigService);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.claimService = this.injector.get(ClaimService);
        this.translate = this.injector.get(TranslateService);
        this.storedDataService = this.injector.get(StoredDataService);
        this.autoSaveService = this.injector.get(AutoSaveService);
        this.pageService = this.injector.get(PageService);
        this.sideMenuService = this.injector.get(SideMenuService);
        this.integrationService = this.injector.get(IntegrationService);
        this.googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
        this.dynamicRoutingService = this.injector.get(DynamicRoutingService);
        this.dateService = this.injector.get(DateService);
    }

    ngOnInit() {

        let refresh = this.pageService.getParameters()['refresh'];
        let refreshPath = this.pageService.getParameters()['refreshPath'];

        if (refresh && refresh == 'true') {
            this.storedDataService.retrieveSessionIfRequired();
            //console.log("refreshPath : ", refreshPath);
            this.googleAnalyticsService.setUpGoogleAnalytics();
            if (refreshPath === 'complete') {
                this.router.navigate(['/complete'], {relativeTo: this.activatedRoute});
            } else {
                let policyType = this.transactionInfoService.getTransactionInfo().getPolicyType();
                this.transactionInfoService.getDocumentTitle(policyType);
                this.router.navigate(['/claimform/' + decodeURIComponent(refreshPath)], {relativeTo: this.activatedRoute});
            }
        } else {

            //console.log('Claim boot Start *****');
            // Every time the url changes we store the browser local data in case of refresh
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    if (event.url.indexOf('claim-boot') === -1) {
                        console.log('ClaimBootComponent Store Session :: Router Change:' + event);
                        // Store any data to the session.
                        this.storedDataService.storeSession();
                    }
                }
                window.scrollTo(0, 0);
            });

            // If we come in through boot component we need to make sure we dont load from browsers storage.
            this.storedDataService.storeSession();

            // Get the parameters in url
            let countryParam = this.pageService.getParameters()['country'];
            let productParam = this.pageService.getParameters()['product'];
            let language = this.pageService.getParameters()['language'];
            let isRepairer = this.pageService.getParameters()['isRepairer'];
            let action = this.pageService.getParameters()['action'];
            let token = this.pageService.getParameters()['token'];
            let iv = this.pageService.getParameters()['iv'];

            if (!countryParam || countryParam == null) {
                countryParam = "HKG";
            } else {
                countryParam = countryParam.toUpperCase();
            }

            var translations: Array<string> = ['en', 'zh'];

            if (!language || UtilitiesService.isNullOrUndefined(language) || (translations.indexOf(language.toLowerCase()) == -1)) {
                language = "en";
            }

            this.translate.use(language.toLowerCase());

            if (!isRepairer || isRepairer === null) {
                isRepairer = false;
            } else {
                isRepairer = true;
            }

            // default to generic product when country param is sgp or mys or when product param is not provided
            if (UtilitiesService.isNullOrUndefined(productParam) || productParam === '' || ['SGP', 'MYS'].indexOf(countryParam) !== -1) {
                productParam = "generic";
            } else {
                productParam = productParam?.toString()?.toLowerCase();
            }

            // Set the channel
            this.transactionInfoService.getTransactionInfo().setChannel(TransactionInfo.EFNOL_CHANNEL);

            this.activatedRoute.queryParams.subscribe(queryParams => {

                //console.log("queryParams" , queryParams);
                // set up the utm details for google analytics
                this.googleAnalyticsService.setUtmSource(queryParams['utm_source']);
                this.googleAnalyticsService.setUtmMedium(queryParams['utm_medium']);
                this.googleAnalyticsService.setUtmCampaign(queryParams['utm_campaign']);

                if (action) {
                    if (action === TransactionInfo.AUTO_SAVE_RESUME && queryParams['integrationToken']) {
                        this.resumeClaimTransaction(queryParams['integrationToken']);
                    } else if ((action === TransactionInfo.EBUSINESS || action === TransactionInfo.CUSTOMER_PORTAL) && queryParams['integrationToken']){
                        this.transactionInfoService.getTransactionInfo().setIsTransactionOrigin(true);
                        this.transactionInfoService.getTransactionInfo().setTransactionApplicationOrigin(queryParams['action']);
                        this.transactionInfoService.getTransactionInfo().setReturnUrl(queryParams['hostName']);
                        this.newAgentClaimTransaction(queryParams['integrationToken'], productParam, countryParam);
                    } else if (action === TransactionInfo.CLAIM_STATUS && queryParams['country']) {
                        this.transactionInfoService.getTransactionInfo().setAction(TransactionInfo.CLAIM_STATUS);
                        let policyNumberParam = this.pageService.getParameters()['policyNumber'];
                        this.newClaimSearchTransaction(countryParam, productParam, policyNumberParam);
                    } else if (action === TransactionInfo.REGISTER || action === TransactionInfo.RESET_PASSWORD) {
                        this.registerUser(token, iv, queryParams['action']);  // windscreen repairer password setup
                    }
                } else {
                    if (token && iv) {
                        this.verifyRedirectToken(token, iv);  // redirect (mox)
                    } else {
                        this.performClaim(countryParam, productParam, isRepairer);
                    }
                }

            });
        }
    }

    registerUser(tokenParam: string, ivParam: string, action: string) {
        const token: string = tokenParam;
        const iv: string = ivParam;
        const country: string = this.transactionInfoService.getTransactionInfo().getRealCountry();
        this.transactionInfoService.getTransactionInfo().setIntegrationToken(token);
        this.transactionInfoService.getTransactionInfo().setIv(iv);
        this.transactionInfoService.getTransactionInfo().setAction(action);
        this.uiConfigService.setConfigUrl('assets/config/ui.' + country.toLowerCase() + '.qbe.claims.fields.json' + Version.getUrlVersion());
        this.uiConfigService.loadUiConfig().subscribe(data => {
            this.router.navigate(['/' + action], {relativeTo: this.activatedRoute});
        })
    }

    performClaim(countryParam, productParam, isRepairer: boolean) {

        let productCode = "";

        switch(productParam) {
            case 'travel': {
                productCode = "PTR"; break;
            }
            case 'helper': {
                productCode = "DMH"; break;
            }
            case 'motor': {
                productCode = "MVA"; break;
            }
            case 'home': {
                productCode = "HPK"; break;
            }
            case 'accident': {
                productCode = "PAN"; break;
            }
            case 'ec': {
                productCode = "WCA"; break;
            }
            default: {
                break;
            }
        }

        let product : Product = new Product();
        product.setProductType(productCode);
        this.transactionInfoService.getTransactionInfo().setProduct(product);
        this.transactionInfoService.getTransactionInfo().setCountry(countryParam);
        this.googleAnalyticsService.setUpGoogleAnalytics();
        this.transactionInfoService.setUpTransactionOrigin(this.googleAnalyticsService.getUtmSource(),this.googleAnalyticsService.getUtmMedium());

        const country: string = this.transactionInfoService.getTransactionInfo().getCountry();
        this.claimService.getClaim().setCountry(country);
        this.transactionInfoService.getTransactionInfo().setIsRepairer(isRepairer);

        this.uiConfigService.setConfigUrl('assets/config/ui.' + country.toLowerCase() + '.qbe.claims.fields.json' + Version.getUrlVersion());

        this.uiConfigService.loadUiConfig().subscribe(
            data => {
                this.router.navigate(['/homepage'], {relativeTo: this.activatedRoute, skipLocationChange : true});
            }
        );

    }

    newAgentClaimTransaction(integrationTokenParam: string, policyType, countryParam) {
        // Token used to pass policy and user information.
        // Make sure the passed in token doesnt have bearer at the front
        let integrationToken = integrationTokenParam.replace('Bearer ','');
        this.transactionInfoService.getTransactionInfo().setIntegrationToken(integrationToken);

        // Call the server to split out the token information.
        this.integrationService.getIntegrationData(integrationToken, policyType).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (policyAuthJson: PolicyAuthJson) => {

                    if (policyAuthJson.getErrorStr() == null || policyAuthJson.getErrorStr() == '') {

                        // Set up what type of transaction it is and other data
                        const claim: Claim = this.claimService.getClaim();
                        let product : Product = new Product();
                        product.setProductType(policyAuthJson.getProductType());
                        this.googleAnalyticsService.setUpGoogleAnalytics();
                        this.transactionInfoService.setUpTransactionOrigin(this.googleAnalyticsService.getUtmSource(),this.googleAnalyticsService.getUtmMedium());
                        this.pushGAOrigin(false);
                        this.transactionInfoService.setupTransaction(policyAuthJson, claim);

                        let country: string = this.transactionInfoService.getTransactionInfo().getRealCountry();
                        this.uiConfigService.setConfigUrl('assets/config/ui.' + country?.toLowerCase() + '.qbe.claims.fields.json' + Version.getUrlVersion());

                        this.uiConfigService.loadUiConfig().subscribe(
                            data => {

                                this.sideMenuService.emitComplete('finishGetStarted');
                                let transactionInfo =  this.transactionInfoService.getTransactionInfo();
                                transactionInfo.getNavigationMenu().setGetStartedState("finish");

                                if (transactionInfo.isTravelProduct()) {
                                    this.sideMenuService.setTotalStep(6);
                                    this.sideMenuService.emitProgress(1);
                                    transactionInfo.getNavigationMenu().setConfirmTravelPeriodState("current");
                                    this.router.navigate(["/claimform/confirmTravelPeriod"], {
                                        relativeTo: this.activatedRoute
                                    });
                                } else if (transactionInfo.isDomesticHelperProduct()) {
                                    this.sideMenuService.setTotalStep(5);
                                    this.sideMenuService.emitProgress(1);
                                    let policyHolderName = this.claim.getPolicyHolderName();
                                    this.sideMenuService.emitSelectInsured(policyHolderName);
                                    transactionInfo.getNavigationMenu().setSelectInsuredState("current");
                                    this.router.navigate(["/claimform/selectInsured"], {
                                        relativeTo: this.activatedRoute
                                    });
                                } else if (transactionInfo.isAccidentProduct()) {

                                    let url = "/claimform/selectInsured";
                                    let totalStep = 6;
                                    if (this.claim.isHKGorHGI()) {
                                        if (transactionInfo.hasSingleInsured()) {
                                            url = "/claimform/paDetailsOfAccident";
                                            totalStep = 5;
                                        } 
                                    } else {
                                        totalStep = 7;
                                    }

                                    let policyHolderName = this.claim.getPolicyHolderName();
                                    this.sideMenuService.emitSelectInsured(policyHolderName);
                                    transactionInfo.getNavigationMenu().setSelectInsuredState("current");

                                    this.sideMenuService.setTotalStep(totalStep);
                                    this.sideMenuService.emitProgress(1);
                                    this.router.navigate([url], { relativeTo: this.activatedRoute });
                                
                                } else if (transactionInfo.isMotorProduct()) {
                                    let policyHolderName = this.claim.getPolicyHolderName();
                                    this.sideMenuService.emitSelectInsured(policyHolderName);
                                    if (transactionInfo.getNumberOfRisks() > 1) {
                                        this.sideMenuService.setTotalStep(6);
                                        this.sideMenuService.emitProgress(1);
                                        transactionInfo.getNavigationMenu().setGetStartedState("current");
                                        this.router.navigate(["/claimform/getStart"], {
                                            relativeTo: this.activatedRoute
                                        });
                                    } else {
                                        this.sideMenuService.setTotalStep(5);
                                        this.sideMenuService.emitProgress(1);
                                        transactionInfo.getNavigationMenu().setConfirmTravelPeriodState("current");
                                        this.router.navigate(["/claimform/confirmVehicleDetails"], {
                                            relativeTo: this.activatedRoute
                                        });
                                    }
                                } else if (transactionInfo.isHomeProduct()) {
                                    this.sideMenuService.setTotalStep(5);
                                    this.sideMenuService.emitProgress(1);
                                    transactionInfo.getNavigationMenu().setGenericClaimDetailState("current");
                                    this.router.navigate(["claimform/confirmIncidentDetails"], {
                                        relativeTo: this.activatedRoute
                                    });
                                } else if (transactionInfo.isGenericClaim()) {
                                    this.sideMenuService.setTotalStep(4);
                                    this.sideMenuService.emitProgress(1);
                                    if (["HKG", "HGI"].indexOf(this.claim.getCountry()) !== -1 && this.claim.getPolicyRisks()?.length > 1 &&
                                        this.claim.getPolicyRisks().find(riskItem =>
                                            TransactionInfo.HK_EC_RISKS.indexOf(riskItem.getRiskType()) !== -1)) {
                                        transactionInfo.setIsFromGeneric(true);
                                        this.router.navigate(["/claimform/genericSelectClaim"], {
                                            relativeTo: this.activatedRoute
                                        });
                                    } else {
                                        transactionInfo.getNavigationMenu().setGenericClaimDetailState("current");
                                        this.router.navigate(["/claimform/genericClaimDetail"], {
                                            relativeTo: this.activatedRoute
                                        });
                                    }
                                } else if (transactionInfo.isEmployeeCompensationProduct()) {
                                    this.sideMenuService.setTotalStep(5);
                                    this.sideMenuService.emitProgress(1);
                                    transactionInfo.getNavigationMenu().setConfirmTravelPeriodState("current");
                                    this.router.navigate(["claimform/employerDetails"], {
                                        relativeTo: this.activatedRoute
                                    });
                                }

                            });

                    } else {
                        this.errorMsg = policyAuthJson.getErrorStr();
                        this.modal.show();
                    }
                },
                error: (err: any) => {
                    this.errorMsg = err;
                    this.modal.show();
                }
            });
      }

    resumeClaimTransaction(integrationTokenParam: string) {

        const integrationToken: string = integrationTokenParam;

        this.autoSaveService.retrieveAutoSave(integrationToken).subscribe({
            next: (autoSaveForm: AutoSaveForm) => {
                if (UtilitiesService.isNullOrUndefined(autoSaveForm)) {
                    this.router.navigate(['/error'], { queryParams: {errorType : 'ResumeLinkExpired'}});
                } else {
                    // set resumed autosaveform
                    this.autoSaveService.setAutoSaveForm(autoSaveForm);

                    this.claimService.setClaim(Claim.jsonConvertClaim(autoSaveForm.getClaim()));

                    // set resume language
                    this.translate.use(autoSaveForm.getClaim().getLanguage());

                    // setup transactionInfo for autoSaveResume
                    this.transactionInfoService.setupAutoSaveTransaction(autoSaveForm, integrationToken);

                    // set up ga when resuming transaction
                    this.googleAnalyticsService.setUpGoogleAnalytics();

                    //setup entry url
                    let autoSaveEntryUrl = this.transactionInfoService.getTransactionInfo().getEntryUrl();
                    if(autoSaveEntryUrl != null && autoSaveEntryUrl != undefined){
                        autoSaveEntryUrl = autoSaveEntryUrl.replace('amp;', '');
                        this.pageService.setEntryUrl(autoSaveEntryUrl);
                    }

                    let autoSaveId = UtilitiesService.isNullOrUndefined(autoSaveForm.getSaveId()) ? '' :  autoSaveForm.getSaveId().toString();
                    this.transactionInfoService.getTransactionInfo().setAutoSaveId(autoSaveId);

                    this.pushGAOrigin(true);
                    const country: string = this.transactionInfoService.getTransactionInfo().getRealCountry();
                    this.uiConfigService.setConfigUrl('assets/config/ui.' + country.toLowerCase() + '.qbe.claims.fields.json' + Version.getUrlVersion());
                    this.uiConfigService.loadUiConfig().subscribe(data => {
                        this.router.navigate(['/claimform/getStart'], {relativeTo: this.activatedRoute});
                    })
                }
            },

            error: (err: any) => {
                console.error('resumeClaimTransaction error', err);
                this.router.navigate(['/error'], { queryParams: {errorType : 'ResumeLinkExpired'}});
            }
        });
    }

    newClaimSearchTransaction(countryParam: string, productParam: string,  policyNumberParam: string) {

        let transactionInfo = this.transactionInfoService.getTransactionInfo();
        let productCode = UtilitiesService.getDefaultProductCode(productParam);
        let product : Product = new Product();
        product.setProductType(productCode);
        transactionInfo.setProduct(product);

        if (!UtilitiesService.isNullOrUndefined(policyNumberParam) && policyNumberParam != "") {
            let policyNumber = policyNumberParam.toUpperCase();
            transactionInfo.setPolicyNumber(policyNumber);
        }

        transactionInfo.setCountry(countryParam);
        this.googleAnalyticsService.setUpGoogleAnalytics();
        this.uiConfigService.setConfigUrl('assets/config/ui.qbe.claims.status.fields.json' + Version.getUrlVersion());
        this.uiConfigService.loadUiConfig().subscribe(
            data => {
                this.router.navigate(['/search-claim-homepage'], {relativeTo: this.activatedRoute, skipLocationChange : true});
            }
        );
    }

    verifyRedirectToken(token: string, iv: string) {

        this.integrationService.verifyRedirectToken(token, iv).subscribe({
            next: (resp) => {
                if (UtilitiesService.isNullOrUndefined(resp)) {
                    this.router.navigate(['/error'], { queryParams: {errorType : 'InvalidMoxLink'}});
                } else {
                    let claim: Claim = Claim.jsonConvertClaim(resp);
                    this.claimService.setClaim(claim);

                    let transactionInfo = this.transactionInfoService.getTransactionInfo();
                    let product : Product = new Product();
                    product.setProductType(claim.getProduct());
                    transactionInfo.setProduct(product);
                    transactionInfo.setCountry(claim.getCountry());

                    this.translate.use(claim.getLanguage()?.toLowerCase() == 'cn' ? 'zh' : 'en');

                    let policyVerifyForm: PolicyVerifyForm = new PolicyVerifyForm();
                    policyVerifyForm.setCountry(claim.getCountry());
                    policyVerifyForm.setPolicyNumber(claim.getPolicyNumber());
                    policyVerifyForm.setPolicyType(transactionInfo.getPolicyType());
                    policyVerifyForm.setLoggingId(transactionInfo.getLoggingId());
                    policyVerifyForm.setName(claim.getPolicyHolderName());
                    policyVerifyForm.setIdNumber(claim.getClaimantIdNumber());
                    policyVerifyForm.setDateOfLoss(this.dateService.dateToString(claim.getDateOfLoss(), "dd/MM/yyyy"));

                    this.integrationService.verifyPolicyNumber(policyVerifyForm)
                        .subscribe({
                            next: (policyAuth: PolicyAuthJson) => {
                                if (policyAuth.getErrorStr() == null || policyAuth.getErrorStr() == '') {
                                    this.transactionInfoService.setupTransaction(policyAuth, claim);

                                    
                                    this.sideMenuService.emitComplete('finishGetStarted');
                                    this.sideMenuService.emitSelectInsured(claim.getClaimantName() ?
                                        claim.getClaimantName() : claim.getPolicyHolderName());

                                    const country: string = this.transactionInfoService.getTransactionInfo().getRealCountry();
                                    this.uiConfigService.setConfigUrl('assets/config/ui.' + country.toLowerCase() + '.qbe.claims.fields.json' + Version.getUrlVersion());

                                    this.uiConfigService.loadUiConfig().subscribe(
                                        data => {
                                            let transactionInfo =  this.transactionInfoService.getTransactionInfo();
                                            if (this.claimService.getClaim().isMox()) {
                                                this.sideMenuService.setTotalStep(transactionInfo.hasSingleInsured() ? 4 : 5);
                                                this.sideMenuService.emitSelectInsured(claim.getClaimantName() ?
                                                    claim.getClaimantName() : claim.getPolicyHolderName());
                                                this.router.navigate(["/claimform/acknowledgement"], {
                                                    relativeTo: this.activatedRoute
                                                });
                                            }
                                        });
                                }
                            },
                            error: (err: any) => {
                                console.error('verifyRedirectToken verifyPolicyNumber error', err);
                                this.router.navigate(['/error'], { queryParams: {errorType : 'InvalidMoxLink'}});
                            }
                        });
                }
            },

            error: (err: any) => {
                console.error('verifyRedirectToken error', err);
                this.router.navigate(['/error'], { queryParams: {errorType : 'InvalidMoxLink'}});
            }
        });
    }


    pushGAOrigin(isResume: boolean) {
        let utmSource = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmSource()) ?
            this.googleAnalyticsService.getUtmSource() : "N/A" ;
        let utmMedium = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmMedium()) ?
            this.googleAnalyticsService.getUtmMedium() : "N/A" ;
        let utmCampaign =  this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmCampaign()) ?
            this.googleAnalyticsService.getUtmCampaign() : "N/A" ;

        if (isResume) {
            // resume save for later
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            (<any>window).dataLayer.push({
                'pageStep': 'Redirect',
                'vPath': '/redirect',
                'utm_source': utmSource,
                'utm_medium': utmMedium,
                'utm_campaign': utmCampaign,
                'event': 'vpageview',
                'ecommerce': {
                    'checkout': {'actionField': {'step': 1, 'option': 'SaveResume'}}
                }
            });
        } else {
            // transaction from agent channel ie. qnect
            let transactionInfo = this.transactionInfoService.getTransactionInfo();
            let pageStep = 'QnectRedirect';
            let vPath = '/qnectredirect';
            let actionStep = 2;
            let policyNumber = transactionInfo.getPolicyNumber();
            if(transactionInfo.getNumberOfRisks() > 1 && transactionInfo.getPolicyType() === 'motor') {
                pageStep = 'QnectRedirectAuthentication';
                vPath = '/qnectredirectauthentication';
                actionStep = 1;
            }
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            (<any>window).dataLayer.push({
                'pageStep': pageStep,
                'vPath': vPath,
                'utm_source': utmSource,
                'utm_medium': utmMedium,
                'utm_campaign': utmCampaign,
                'event': 'vpageview',
                'PolicyNo': policyNumber,
                'ecommerce': {
                    'checkout': {'actionField': {'step': actionStep, 'option': 'Qnectlodge'}}
                }
            });
        }
    }
}
