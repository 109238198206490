import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";
import { FormBuilder, FormGroup} from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { DocumentFormFactoryService } from "src/app/services/document-form-factory.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ClaimDocumentsComponent } from "src/app/claim-pages/claim-documents/claim-documents.component";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { TranslateService } from "@ngx-translate/core";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ClinicalExpenseDetails } from "src/app/model/personal-accident/clinical-expense-details.model";

@Component({
    selector: 'app-pa-upload-doc',
    templateUrl: './pa-upload-doc.component.html',
    styleUrls: ['./pa-upload-doc.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PaUploadDocComponent extends ClaimDocumentsComponent implements OnInit, OnChanges {

    upLoadFileModel: FormGroup;
    country: string;
    public showAddDoc = false;
    transInfo: TransactionInfo;
    @Input() wasAdmitted;
    @Input() clinicalExpenses: ClinicalExpenseDetails[];
    isMox: boolean;

    constructor(private fb: FormBuilder,
                public translate: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(transactionInfoService.getTransactionInfo().getCurrentClaimType());
                this.country = claimService.getClaim().getCountry();
                this.transInfo = transactionInfoService.getTransactionInfo();
                this.isMox = claimService.getClaim().isMox();
    }

    ngOnInit() {
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                otherItems: this.fb.array([this.createItem()])
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] === this.transInfo.getCurrentClaimType()) {
            
            //Change in wasAdmitted
            if(!changes?.wasAdmitted?.firstChange && changes?.wasAdmitted?.currentValue !== changes?.wasAdmitted?.previousValue) {
                super.ngOnInit();  
                setTimeout(() => {
                    this.populateUploadedDocumentFields();
                }, 10);
            }

            //Change in ClinicalExpenses if !wasAdmitted
            if(!changes?.clinicalExpenses?.firstChange && this.wasAdmitted === false && 
                changes?.clinicalExpenses?.currentValue !== changes?.clinicalExpenses?.previousValue) {
                super.ngOnInit();
                setTimeout(() => {
                    this.populateUploadedDocumentFields();
                }, 10);
            }
        }
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: []
        });
    }

    goToNext() {
        if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] === this.transInfo.getCurrentClaimType()) {
            let wasAdmittedAME = this.claimService.getClaim().getClaimPersonalAccident()?.getAccidentalMedicalExpenses()?.getWasAdmitted();
            let listOfStpDocs = !wasAdmittedAME ? ['RECEIPTS', 'REFERRALLETTER', 'MEDCERT'] : [];
            let stpMessage = this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, listOfStpDocs);
            if(!UtilitiesService.isEmpty(stpMessage) && this.claimService.getClaim().isHKGorHGI()){
                this.showDocAlertModal();
            } else {
                this.redirectToNext();
            }
        }
    }

    redirectToNext() {
        let url = this.claimService.getClaim().isHKGorHGI() ? "/claimform/anotherInsurance" : "/claimform/paymentAndContact";
        let subIndex = this.claimService.getClaim().isHKGorHGI() ? 0 : 5;
        
        this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: subIndex});
        this.sideMenuService.emitProgress(1);
        this.sideMenuService.emitComplete('finishChooseClaim');
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    showDocAlertModal() {
        this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    continueToNext() { 
        this.redirectToNext();
        this.docAlertModalRef.close();
    }

}
